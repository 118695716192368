import Header from './Header.jsx';
import About from './About.jsx';
import Edu from './Edu.jsx';
import Portfolio from './Portfolio.jsx';
import RTPorto from '../components/RTPorto.jsx';
import Footer from './Footer.jsx';

function Home (){
  return(
    <section> 
      <Header/>
      <div class="mx-auto max-w-screen-2xl" id="knowmore">
        <About/>
        <Edu/>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='-mt-32'><path fill="#000000" fill-opacity="1" d="M0,128L12.6,122.7C25.3,117,51,107,76,117.3C101.1,128,126,160,152,165.3C176.8,171,202,149,227,160C252.6,171,278,213,303,229.3C328.4,245,354,235,379,197.3C404.2,160,429,96,455,90.7C480,85,505,139,531,149.3C555.8,160,581,128,606,144C631.6,160,657,224,682,218.7C707.4,213,733,139,758,128C783.2,117,808,171,834,176C858.9,181,884,139,909,112C934.7,85,960,75,985,96C1010.5,117,1036,171,1061,186.7C1086.3,203,1112,181,1137,149.3C1162.1,117,1187,75,1213,90.7C1237.9,107,1263,181,1288,218.7C1313.7,256,1339,256,1364,234.7C1389.5,213,1415,171,1427,149.3L1440,128L1440,320L1427.4,320C1414.7,320,1389,320,1364,320C1338.9,320,1314,320,1288,320C1263.2,320,1238,320,1213,320C1187.4,320,1162,320,1137,320C1111.6,320,1086,320,1061,320C1035.8,320,1011,320,985,320C960,320,935,320,909,320C884.2,320,859,320,834,320C808.4,320,783,320,758,320C732.6,320,707,320,682,320C656.8,320,632,320,606,320C581.1,320,556,320,531,320C505.3,320,480,320,455,320C429.5,320,404,320,379,320C353.7,320,328,320,303,320C277.9,320,253,320,227,320C202.1,320,177,320,152,320C126.3,320,101,320,76,320C50.5,320,25,320,13,320L0,320Z"></path></svg>
        <RTPorto direction="left" bgColor="bg-black" textColor="text-white"/>
        <RTPorto direction="right" bgColor="bg-black" textColor="text-white"/>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='-mb-24'><path fill="#000000" fill-opacity="1" d="M0,288L8.6,250.7C17.1,213,34,139,51,144C68.6,149,86,235,103,266.7C120,299,137,277,154,245.3C171.4,213,189,171,206,138.7C222.9,107,240,85,257,74.7C274.3,64,291,64,309,90.7C325.7,117,343,171,360,197.3C377.1,224,394,224,411,229.3C428.6,235,446,245,463,229.3C480,213,497,171,514,149.3C531.4,128,549,128,566,112C582.9,96,600,64,617,64C634.3,64,651,96,669,96C685.7,96,703,64,720,69.3C737.1,75,754,117,771,133.3C788.6,149,806,139,823,117.3C840,96,857,64,874,74.7C891.4,85,909,139,926,154.7C942.9,171,960,149,977,160C994.3,171,1011,213,1029,218.7C1045.7,224,1063,192,1080,181.3C1097.1,171,1114,181,1131,160C1148.6,139,1166,85,1183,80C1200,75,1217,117,1234,160C1251.4,203,1269,245,1286,261.3C1302.9,277,1320,267,1337,224C1354.3,181,1371,107,1389,85.3C1405.7,64,1423,96,1431,112L1440,128L1440,0L1431.4,0C1422.9,0,1406,0,1389,0C1371.4,0,1354,0,1337,0C1320,0,1303,0,1286,0C1268.6,0,1251,0,1234,0C1217.1,0,1200,0,1183,0C1165.7,0,1149,0,1131,0C1114.3,0,1097,0,1080,0C1062.9,0,1046,0,1029,0C1011.4,0,994,0,977,0C960,0,943,0,926,0C908.6,0,891,0,874,0C857.1,0,840,0,823,0C805.7,0,789,0,771,0C754.3,0,737,0,720,0C702.9,0,686,0,669,0C651.4,0,634,0,617,0C600,0,583,0,566,0C548.6,0,531,0,514,0C497.1,0,480,0,463,0C445.7,0,429,0,411,0C394.3,0,377,0,360,0C342.9,0,326,0,309,0C291.4,0,274,0,257,0C240,0,223,0,206,0C188.6,0,171,0,154,0C137.1,0,120,0,103,0C85.7,0,69,0,51,0C34.3,0,17,0,9,0L0,0Z"></path></svg>
        <Portfolio/>
        <Footer/>
      </div>
      
    </section>
  )
}

export default Home;