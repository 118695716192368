import ProjectCard from "../components/ProjectCard";
import RTTools from "../components/RTTools";
import Certif from "../components/Certif";
import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

SwiperCore.use([Navigation, Pagination]);

function Portfolio() {
  const [slidesPerView, setSlidesPerView] = useState(4);

  const swiperRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      if (innerWidth <= 678) {
        setSlidesPerView(1);
      } else if (innerWidth <= 980) {
        setSlidesPerView(2);
      } else if (innerWidth <= 1115) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(4);
      }
    };

    handleResize(); 

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="bg-white">
      <div class="max-w-screen-xl px-4 py-24 mx-auto">
        <header class="bg-red-300 w-64">
          <h2 class="text-5xl font-bold tracking-widest ">
            Projects
          </h2>
        </header>

        <Swiper
          ref={swiperRef}
          className="mt-12"
          slidesPerView={slidesPerView}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          <SwiperSlide className='py-4 lg:px-4 px-8'>
            <ProjectCard
              title="Indonesia Recovery"
              title2="Indonesia Recovery"
              borderCol="border-red-300"
              desc="Indonesia Recovery is a web design project for the Indonesian Vocational Olympiad (OLIVIA) 2022 in a form of a news portal aggregator website that provides information about the rise of new enthusiasm after the COVID-19 pandemic by spreading positive news and relating to digital transformation in Indonesia."
              link="https://indonesia-recovery.vercel.app/"
            />
          </SwiperSlide>

          <SwiperSlide className='py-4 lg:px-4 px-8 '>
            <ProjectCard
              title="Exdecode"
              title2="Exdecode"
              borderCol="border-green-300"
              desc="Exdecode is a community focused on software development and information technology. Exdecode provide a place for developers, coders, designers and technology enthusiasts to share knowledges, experiences and new ideas in the world of information technology."
              link="https://exdecode.vercel.app/"
            />
          </SwiperSlide>

          <SwiperSlide className='py-4 lg:px-4 px-8 '>
            <ProjectCard
              title="Masjid Alumni IPB"
              title2="Masjid Alumni IPB"
              borderCol="border-yellow-300"
              desc="The IPB Alumni Mosque website is an online platform specifically designed to provide information and services about religious activities within the IPB Alumni Mosque. This website provides information such as prayer schedules, mosque contacts, religious agendas and more."
              link="https://masjidalumniipb.netlify.app/"
            />
          </SwiperSlide>

          <SwiperSlide className='py-4 lg:px-4 px-8 '>
            <ProjectCard
              title="Not Yet"
              title2="Not Yet"
              borderCol="border-blue-300"
              desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              link="/"
            />
          </SwiperSlide>

          <SwiperSlide className='py-4 lg:px-4 px-8 '>
            <ProjectCard
              title="Not Yet"
              title2="Not Yet"
              borderCol="border-red-300"
              desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              link="/"
            />
          </SwiperSlide>
          <SwiperSlide className='py-4 lg:px-4 px-8 '>
            <ProjectCard
              title="Not Yet"
              title2="Not Yet"
              borderCol="border-yellow-300"
              desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              link="/"
            />
          </SwiperSlide>
        </Swiper>

        <header class="bg-green-300 w-64">
          <h2 class="text-5xl font-bold mt-24 mt-10 tracking-widest">
            Skills
          </h2>
        </header>
        <RTTools />

      </div>

    </section>

  )
}

export default Portfolio;